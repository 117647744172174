<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M6.5,10h11c0.276,0,0.5-0.224,0.5-0.5v-9C18,0.223,17.776,0,17.5,0h-11C6.224,0,6,0.223,6,0.5v9
      C6,9.776,6.224,10,6.5,10z M7,1h10v8H7V1z"/>
    <path
      d="M5.5,19h-5C0.224,19,0,19.223,0,19.5v4C0,23.776,0.224,24,0.5,24h5C5.776,24,6,23.776,6,23.5v-4
      C6,19.223,5.776,19,5.5,19z M5,23H1v-3h4V23z"/>
    <path
      d="M14.5,19h-5C9.224,19,9,19.223,9,19.5v4C9,23.776,9.224,24,9.5,24h5c0.276,0,0.5-0.224,0.5-0.5v-4
      C15,19.223,14.776,19,14.5,19z M14,23h-4v-3h4V23z"/>
    <path
      d="M23.5,19h-5c-0.276,0-0.5,0.224-0.5,0.5v4c0,0.276,0.224,0.5,0.5,0.5h5c0.276,0,0.5-0.224,0.5-0.5v-4
      C24,19.223,23.776,19,23.5,19z M23,23h-4v-3h4V23z"/>
    <path
      d="M3.5,17.5C3.776,17.5,4,17.276,4,17v-2h7.545v2c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-2H20v2
      c0,0.276,0.224,0.5,0.5,0.5S21,17.276,21,17v-2.5c0-0.276-0.224-0.5-0.5-0.5h-7.955v-2c0-0.276-0.224-0.5-0.5-0.5
      s-0.5,0.224-0.5,0.5v2H3.5C3.224,14,3,14.223,3,14.5V17C3,17.276,3.224,17.5,3.5,17.5z"/>
  </svg>
</template>
